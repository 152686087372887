import React from "react";

import { Layout, SEO } from "../../components/structure";
import {
  Button,
  Content,
  Hero,
  HospitalityPremiumUpgrade,
  Testimonial,
  HospitalityPointsLoyalty,
  CallToAction,
  SeatBlocker,
  SpeedPass,
  HospitalityLoyaltyCommerce,
  StayPlus,
  StayExtend,
  HospitalitySplitBlock,
} from "../../components/blocks";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const HospitalityPage = props => {
  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "GettyImages-457982209.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIcon: file(base: { eq: "arrow-right-blue.png" }) {
        ...ButtonIcon
      }
      rightArrowIconWhite: file(base: { eq: "arrow-right.png" }) {
        ...ButtonIcon
      }
      hospitalityIcon: file(base: { eq: "icon-Hospitality.png" }) {
        childImageSharp {
          fixed(width: 40, height: 43, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      clickUpgradeIcon: file(base: { eq: "click-upgrade.png" }) {
        childImageSharp {
          fixed(width: 45, height: 80, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      premiumIcon: file(
        base: { eq: "Icons_Product_hospitality_final_PremiumUpgrade.png" }
      ) {
        childImageSharp {
          fixed(width: 51, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      StayExtendImage: file(base: { eq: "StayExtended-Mobile-2023.png" }) {
        childImageSharp {
          fixed(width: 280, height: 540, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      StayPlusImage: file(base: { eq: "Resized%20-%20StayPlus.png" }) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      partnerIncrease: file(base: { eq: "partnerIncrease.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      hotelPartnersIcon: file(base: { eq: "Layer_1.png" }) {
        childImageSharp {
          fixed(height: 75, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      yoyIcon: file(base: { eq: "33.png" }) {
        childImageSharp {
          fixed(height: 75, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      averagePartnerIcon: file(base: { eq: "182.png" }) {
        childImageSharp {
          fixed(height: 75, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      revenueIncrease: file(base: { eq: "revenueIncrease.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cloudUpload: file(base: { eq: "cloudUpload.png" }) {
        childImageSharp {
          fixed(width: 70, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      creditCard: file(base: { eq: "creditCard.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lcrProductImage: file(
        base: { eq: "Hospitality%20-%20Loyalty%20Currency%20Retailing.png" }
      ) {
        childImageSharp {
          fixed(width: 260, height: 410, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lppProductImage: file(
        base: { eq: "Hospitality%20-%20Loyalty%20Commerce%20Platform.png" }
      ) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      loyaltyIcon: file(base: { eq: "handPlus.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      speedPassProduct: file(base: { eq: "Product-Speed-Pass.png" }) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      offerDistribute: file(base: { eq: "offerDistribute.png" }) {
        childImageSharp {
          fixed(width: 70, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      revPlatformBackground: file(base: { eq: "GettyImages-1397651779.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      hospitalityTestimonials: file(
        base: { eq: "GettyImages-903417402-min.jpg" }
      ) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      growthArrow: file(base: { eq: "ArrowClimber.png" }) {
        childImageSharp {
          fixed(width: 500, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pyramid: file(base: { eq: "GettyImages-901630488.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 400, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobilePyramid: file(base: { eq: "GettyImages-901630488.jpg" }) {
        childImageSharp {
          fixed(width: 1000, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      marriotHotel: file(base: { eq: "MarriottColour.png" }) {
        ...CruiseLogo
      }
      ihgHotel: file(base: { eq: "IHG-NewLogo.png" }) {
        ...CruiseLogo
      }
      grecoteHotel: file(base: { eq: "GrecotelColour.png" }) {
        ...CruiseLogo
      }
      LeonardoHotel: file(base: { eq: "leonardo_red.png" }) {
        ...CruiseLogo
      }
      hyattHotel: file(base: { eq: "Hyatt.png" }) {
        ...CruiseLogo
      }
      stayCityHotel: file(base: { eq: "StayCityApartHotels.png" }) {
        ...CruiseLogo
      }

      airCanada: file(base: { eq: "IHG.jpeg" }) {
        ...CruiseLogo
      }
      lingus: file(base: { eq: "AerLingus_Inline.png" }) {
        ...CruiseLogo
      }
      latam: file(base: { eq: "Latam_Inline.png" }) {
        ...CruiseLogo
      }
      va: file(base: { eq: "VA_Inline.png" }) {
        ...CruiseLogo
      }
      lufthansa: file(base: { eq: "Lufthansa_Inline.png" }) {
        ...CruiseLogo
      }
      singapore: file(base: { eq: "Singapore_Inline.png" }) {
        ...CruiseLogo
      }
      etihad: file(base: { eq: "EtihadGold.png" }) {
        ...CruiseLogo
      }
      qantas: file(base: { eq: "Qantas.png" }) {
        ...CruiseLogo
      }

      effortlessImplementationIcon: file(base: { eq: "11.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      zeroRiskSolutionsIcon: file(base: { eq: "163.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      dataDrivenStrategyIcon: file(base: { eq: "101.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      amplifiedCustomerEngagementIcon: file(base: { eq: "161.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      seamlessIntegrationIcon: file(base: { eq: "184.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      merchantRecord: file(base: { eq: "merchantRecordIcon.png" }) {
        childImageSharp {
          fixed(width: 90, height: 110, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fullInt: file(base: { eq: "fullIntegrationIcon.png" }) {
        childImageSharp {
          fixed(width: 70, height: 110, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fastestTime: file(base: { eq: "fastestTimeIcon.png" }) {
        childImageSharp {
          fixed(width: 95, height: 110, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      simpleUX: file(base: { eq: "simpleUX.png" }) {
        childImageSharp {
          fixed(width: 50, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      generateAncillary: file(base: { eq: "generateAncillary.png" }) {
        childImageSharp {
          fixed(width: 58, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      inventoryControl: file(base: { eq: "inventoryControl.png" }) {
        childImageSharp {
          fixed(width: 50, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      HeroRightArrowTriColor: file(
        base: { eq: "HorizontalWebArrowTriColour.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const PlatformRevenueContainer = styled.div`
    ${font.imports.sherika}
    width: 100%;
    height: auto;
    min-height: 500px;
    margin: auto;
    position: relative;

    .background-con {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      /*filter: grayscale(1) contrast(1.2);*/

      &:after {
        content: "";
        position: absolute;
        z-index: 350;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba(0, 4, 20, 0.65),
          rgba(0, 4, 20, 0.65)
        );
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .content-con {
      position: relative;
      z-index: 400;
      width: 95%;
      max-width: 1400px;
      margin: auto;
      height: 100%;
      padding: 20px 0;

      h3 {
        font-size: 38px;
        text-align: center;
        color: ${brand.colors.pg_blue};
        font-weight: 600;
        text-shadow: 0 0 40px ${brand.colors.pg_primary_dark};
      }

      h4 {
        font-size: 24px;
        text-align: center;
        color: #fff;
        font-weight: 600;
      }

      .icon-content-con {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        top: 66px;
        margin-bottom: 0;

        @media only screen and ${mq.maxMd} {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          top: 0;
          width: 95%;
          margin: auto;
          max-width: 500px;
        }
      }
    }
  `;

  const OutperformContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    background-color: ${brand.colors.pg_primary_light};

    .left-section {
      flex-basis: 50%;
      height: 450px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .right-section {
      height: 450px;
      flex-basis: 50%;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 90px;

      .blue-highlight {
        font-size: 80px;
        color: ${brand.colors.pg_blue};
        display: block;
      }

      .copy {
        display: block;
        font-size: 22px;
        color: #000414;
        width: 54%;
        max-width: 350px;
        margin: 15px 0;
      }
    }
  `;

  const ProductsContainerLCR = styled.div`
    ${font.imports.sherika}
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0 2%;
    padding-top: 80px;

    @media only screen and ${mq.maxMd} {
      width: 95%;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    .left-con,
    .right-con {
      flex-basis: 48%;
    }

    .left-con {
      display: flex;
      justify-content: center;
      padding-right: 130px;
      @media only screen and ${mq.maxMd} {
        padding-right: 0;
      }
    }

    .right-con {
      .icon-con {
        @media only screen and ${mq.maxMd} {
          margin: auto;
          text-align: center;
        }
      }
      .copy {
        h2 {
          font-family: ${font.family.sherika};
          font-weight: 600;
          font-size: 36px;
          color: ${brand.colors.pg_primary_dark};
          white-space: pre-wrap;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }

        p {
          font-family: ${font.family.sherika};
          font-weight: 400;
          font-size: 20px;
          color: ${brand.colors.pg_secondary_dark};
          line-height: 22px;
          max-width: 450px;
          margin-bottom: 30px;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }
      }
      > span {
        text-align: center;
        display: block;
        width: 300px;
      }
    }
  `;

  const ProductsHeroContainer = styled.div`
    width: 100%;
    max-width: 1400px;
    /*margin: 80px auto;*/
    margin: 80px auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2%;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
    }

    > div {
      flex-basis: 48%;
    }

    .graphic-con {
      width: 100%;
      max-width: 700px;
      height: 300px;

      @media only screen and ${mq.maxMd} {
        align-self: flex-start;
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .title-con {
      h2 {
        white-space: pre-wrap;
        font-family: ${font.family.sherika};
        font-weight: 700;
        font-size: 80px;
        color: ${brand.colors.pg_orange};
        padding-top: 135px;
        @media only screen and ${mq.maxMd} {
          text-align: center;
          font-size: 60px;
        }
      }
    }
  `;

  const CruiseParnters = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px auto;

    .cruise-logo-container {
      width: 95%;
      max-width: 1400px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;

      @media only screen and ${mq.maxMd} {
        flex-wrap: wrap;
        justify-content: center;
      }

      .logo-con {
        flex-basis: 200px;
      }
    }
  `;

  const PlatformDetailContainer = styled.div`
    width: 100%;
    background-color: ${brand.colors.pg_blue};

    .inner-icon-container {
      width: 95%;
      max-width: 1020px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      gap: 40px;
      justify-content: flex-start;

      @media only screen and ${mq.maxMd} {
        width: 80%;
      }
    }

    .platform-headline {
      display: inline-block;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      margin-top: 2rem;

      /*
       .headline-con {
         margin-bottom:0;
       }
       */
    }
  `;

  return (
    <Layout>
      <SEO
        title="Hospitality | Plusgrade"
        description={
          "Plusgrade accelerates your incredible passenger experiences while generating significant ancillary revenue on every flight."
        }
        keywords={"airline, flight, upgrades"}
      />
      <Content whiteBG>
        <Hero
          title={"Hospitality"}
          image={pageQuery["hero"]}
          icon={pageQuery["hospitalityIcon"]}
          subtitle={
            "Enhance guest experiences and unlock new revenue streams at every interaction."
          }
          buttonURL={"/industries/hospitality/demo/"}
          buttonLabel={"Book a demo"}
          buttonIcon={pageQuery["rightArrowIconWhite"]}
          fullBG
        />

        <CruiseParnters>
          <div className="cruise-logo-container">
            <div className="logo-con">
              <GatsbyImage
                fluid={pageQuery["marriotHotel"].childImageSharp.fluid}
                imgStyle={{
                  objectFit: "none",
                  objectPosition: "50% 50%",
                }}
              />
            </div>
            <div className="logo-con">
              <GatsbyImage
                fluid={pageQuery["ihgHotel"].childImageSharp.fluid}
                imgStyle={{
                  objectFit: "none",
                  objectPosition: "50% 50%",
                }}
              />
            </div>
            <div className="logo-con">
              <GatsbyImage
                fluid={pageQuery["grecoteHotel"].childImageSharp.fluid}
                imgStyle={{
                  objectFit: "none",
                  objectPosition: "50% 50%",
                }}
              />
            </div>
            <div className="logo-con">
              <GatsbyImage
                fluid={pageQuery["LeonardoHotel"].childImageSharp.fluid}
                imgStyle={{
                  objectFit: "none",
                  objectPosition: "50% 50%",
                }}
              />
            </div>
            <div className="logo-con">
              <GatsbyImage
                fluid={pageQuery["hyattHotel"].childImageSharp.fluid}
                imgStyle={{
                  objectFit: "none",
                  objectPosition: "50% 50%",
                }}
              />
            </div>
            <div className="logo-con">
              <GatsbyImage
                fluid={pageQuery["stayCityHotel"].childImageSharp.fluid}
                imgStyle={{
                  objectFit: "none",
                  objectPosition: "50% 50%",
                }}
              />
            </div>
          </div>
          <Button
            destination={"/partners/"}
            label={"See all partners"}
            icon={pageQuery["rightArrowIconWhite"]}
            black
            whiteText
            pill
          />
        </CruiseParnters>

        <CallToAction
          primaryLightBg
          title={"Hotel upselling solutions"}
          subtitle={
            "We’re redefining the guest experience, offering the ultimate platform for\ndelivering hospitality excellence. Our comprehensive solution is built to\nelevate guest satisfaction, drive revenue, and seamlessly digitize and\nautomate your operations."
          }
        >
          <Button
            destination={"/industries/hospitality/demo/"}
            label={"Get started"}
            icon={pageQuery["rightArrowIconWhite"]}
            blue
            pill
            whiteText
          />
        </CallToAction>

        <PlatformRevenueContainer>
          <div className="background-con">
            <GatsbyImage
              fixed={pageQuery["revPlatformBackground"].childImageSharp.fixed}
              imgStyle={{ objectFit: "cover", objectPosition: "50% 80%" }}
            />
          </div>
          <div className="content-con">
            <div className="icon-content-con">
              <IconContainer
                icon={pageQuery["hotelPartnersIcon"]}
                headline={"600+"}
                copy={"hotel properties"}
              />
              <IconContainer
                icon={pageQuery["yoyIcon"]}
                headline={"32%"}
                copy={"YoY average\npartner growth"}
              />
              <IconContainer
                icon={pageQuery["averagePartnerIcon"]}
                headline={"14%"}
                copy={"average partner conversion rate"}
              />
            </div>
          </div>
        </PlatformRevenueContainer>

        <div style={{ backgroundColor: "#F6F6F6", paddingTop: "1px" }}>
          <HospitalityPremiumUpgrade
            title={"Premium Upgrade"}
            copy={
              "Provide hotel guests with the option to bid on or purchase premium room upgrades through a seamless, white-label user experience. <strong>Premium Upgrade</strong> increases ancillary revenue while delivering an exceptional guest experience."
            }
            buttonDest={"/industries/hospitality/upgrade"}
            buttonLabel={"Learn more"}
            icon={pageQuery["premiumIcon"]}
          ></HospitalityPremiumUpgrade>
        </div>

        <StayExtend
          copy={
            "Unlock a world of flexibility for your guests, allowing them to check-in early, extend their stay, or check-out later. <strong>StayExtend</strong> revolutionizes revenue generation by maximizing high-margin opportunities while delivering unparalleled guest experiences."
          }
          buttonLabel={"Learn more"}
          graphicStyle={{ objectPosition: "center top" }}
          image={pageQuery["StayExtendImage"]}
          id="speed-pass"
          buttonDestination={"/industries/hospitality/stayextend"}
          style={{ marginTop: "-60px" }}
          productPage
        />

        <Content secondaryLightBG noMargin>
          <StayPlus
            copy={
              "Empower guests to personalize their stays with access to a multitude of post-booking amenities, from pre-reserved spa sessions and dinners to on-property rentals and event access. <strong>StayPlus</strong> targeted offers cater to individual preferences, boosting sales and conversion rates for add-on services, creating substantial revenue for global hotels."
            }
            buttonLabel={"Learn more"}
            graphicStyle={{ objectPosition: "center top", top: "10%" }}
            image={pageQuery["StayPlusImage"]}
            id="speed-pass1"
            buttonDest={"/industries/hospitality/stayplus"}
            style={{ marginTop: "-60px" }}
            productPage
          />
        </Content>

        <PlatformDetailContainer>
          <div className="inner-icon-container">
            <div className="platform-headline">
              <h2 className="headline-con">Transform your guest experience</h2>
            </div>
            <IconContainer
              whiteOnBlue
              icon={pageQuery["effortlessImplementationIcon"]}
              headline={"Effortless implementation"}
              copy={
                "Experience seamless automation with our ready-to-use ancillary solutions, simplifying the implementation process from day one."
              }
            />

            <IconContainer
              whiteOnBlue
              icon={pageQuery["amplifiedCustomerEngagementIcon"]}
              headline={"Amplified customer engagement"}
              copy={
                "Increase revenue with personalized, customer-set maximum bids to lift conversions."
              }
            />

            <IconContainer
              whiteOnBlue
              icon={pageQuery["zeroRiskSolutionsIcon"]}
              headline={"Zero-risk solutions"}
              copy={
                "Unlock maximum benefits without any implementation fees or upfront costs with our white-label solutions, ensuring a risk-free investment."
              }
            />
            <IconContainer
              whiteOnBlue
              icon={pageQuery["seamlessIntegrationIcon"]}
              headline={"Seamless integration"}
              copy={
                "Our system easily integrates with leading PMS systems and channel and revenue managers."
              }
            />
            <IconContainer
              whiteOnBlue
              icon={pageQuery["dataDrivenStrategyIcon"]}
              headline={"Data-driven strategy"}
              copy={
                "Gain a competitive edge with our data-driven strategy, leveraging market insights from our extensive global ancillary network."
              }
            />
          </div>
        </PlatformDetailContainer>

        <Testimonial
          testimonials={[
            "[Plusgrade] helps us provide our guests with an amazing value for their money based on our unsold inventory. The solution quickly generated consistent conversions and revenue from the very first moment it went live for our hotel.--Ilan Levy, Director General, Herods Hotel",
            "In a time when occupancy has decreased and booking rates are lower, [Plusgrade] helps us to expedite recovery by increasing revenue through upsell transactions. The company uses proprietary technologies including its unique Bid-For-Upgrade solution to meet guests’ demand for upgraded rooms and services.--Idit Haham Keren, Revenue Manager, Fattal Hotels",
            "[Plusgrade] significantly increases our chain’s revenues from room upgrades. As it is fully automated, we don’t need to invest any time or resources in the solutions - it does it all by itself.--Elizabeth Nachmin, Revenue Manager, The Setai Hotel Group",
          ]}
          background={pageQuery["hospitalityTestimonials"]}
          imageStyle={{ objectPosition: "center center" }}
        />

        <Content noMargin>
          <ProductsHeroContainer>
            <div className="graphic-con">
              <GatsbyImage
                fluid={
                  pageQuery["HeroRightArrowTriColor"].childImageSharp.fluid
                }
                imgStyle={{ objectPosition: "right center" }}
              />
            </div>
            <div className="title-con">
              <h2>{"Effortless\nintegration"}</h2>
            </div>
          </ProductsHeroContainer>
        </Content>

        <Content orangeBG>
          <HospitalitySplitBlock
            copy={
              "Transform your hotel operations with our upselling solutions. Designed for seamless integration with your current brand and property management system, our solution automates upselling to supercharge your revenue potential while enhancing your guest experiences. "
            }
            buttonLabel={"See all integrations"}
            graphicStyle={{ objectPosition: "center top" }}
            image={pageQuery["pyramid"]}
            id="speed-pass2"
            buttonDestination={"/industries/hospitality/integrations"}
            style={{ marginTop: "-60px" }}
            productPage
          />
        </Content>

        <Content secondaryLightBG>
          <HospitalityLoyaltyCommerce
            title={"Loyalty Currency\nRetailing"}
            copy={
              "Give your loyalty program members the option to buy, gift, and transfer their points. <strong>Loyalty Currency Retailing</strong> provided by Points, a Plusgrade company, turns singular earn-focused loyalty rewards programs into multi-product revenue drivers."
            }
            buttonLabel={"Visit Points to learn more"}
            graphicStyle={{ objectPosition: "center top" }}
            image={pageQuery["lcrProductImage"]}
            id="speed-pass3"
            buttonDestination={"https://www.points.com/"}
            style={{ marginTop: "0px" }}
            productPage
          />
        </Content>

        <HospitalityPointsLoyalty
          title={"Loyalty Platform\nPartnerships"}
          copy={
            "Enhance your guests ability to exchange, earn, and redeem loyalty points across a variety of hotel rewards programs. Through our white-label third-party or co-branded channel, <strong>Loyalty Platform Partnerships</strong> by Points, a Plusgrade company, provides access to a thriving network of premier merchant partners."
          }
          buttonLabel={"Visit Points to learn more"}
          graphicStyle={{ objectPosition: "center top", top: "10%" }}
          image={pageQuery["lppProductImage"]}
          id="speed-pass4"
          buttonDest={"https://www.points.com/"}
          style={{ marginTop: "-60px" }}
          productPage
        />

        <CallToAction
          title={"Drive ancillary revenue with\nincredible guest experiences"}
          subtitle={"Contact us today to learn more."}
        >
          <Button
            destination={"/industries/hospitality/demo/"}
            label={"Request a demo"}
            icon={pageQuery["rightArrowIcon"]}
            white
            blueText
            pill
          />
        </CallToAction>
      </Content>
    </Layout>
  );
};

export default HospitalityPage;

const IconCon = styled.div`
  ${font.imports.sherika}
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and ${mq.maxMd} {
    flex-basis: 95%;
  }

  .icon-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }
  }

  .headline-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }
  }

  .headline {
    font-family: ${font.family.sherika};
    font-weight: 600 !important;
    color: ${brand.colors.pg_orange} !important;
    font-size: 48px !important;
    margin-bottom: 1px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
  }

  p {
    margin-top: 1px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  ${props =>
    props.whiteOrange &&
    css`
      flex-basis: 30%;
      align-items: flex-start;
      flex-wrap: wrap;

      .headline-con {
        text-align: left;
        h4 {
          color: ${brand.colors.pg_primary_dark} !important;
          font-family: ${font.family.sherika};
          font-size: 22px !important;
          font-weight: 700;
          white-space: pre-line;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }
        p {
          color: ${brand.colors.pg_primary_dark};
          font-family: ${font.family.sherika};
          font-weight: 400;
          margin: 20px 0;
          min-height: 96px;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }

        .headline {
          min-height: 60px;
          display: flex;
          align-items: center;
          @media only screen and ${mq.maxMd} {
            text-align: center;
            justify-content: center;
          }
        }
      }
    `}

  ${props =>
    props.whiteOnBlue &&
    css`
      flex-basis: 45%;
      align-items: flex-start;
      justify-content: center;
      text-align: left;

      .icon-con {
        text-align: left;
      }

      .headline-con {
        .headline {
          color: #fff !important;
          font-size: 26px !important;
        }
      }

      p {
        font-size: 16px !important;
        font-weight: 400 !important;
        margin-bottom: 0;
      }
    `}
`;

const IconContainer = ({ icon, highlight, headline, copy, ...props }) => {
  const ic = icon ? <GatsbyImage fixed={icon.childImageSharp.fixed} /> : <></>;
  return (
    <IconCon {...props}>
      <div className="icon-con">{ic}</div>
      <div className="headline-con">
        <h4 className="headline-highlight">{highlight}</h4>
        <h4 className="headline">{headline}</h4>
        <p dangerouslySetInnerHTML={{ __html: copy }}></p>
      </div>
    </IconCon>
  );
};

export const query = graphql`
  fragment CruiseLogo on File {
    childImageSharp {
      fluid(maxWidth: 125, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
